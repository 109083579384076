import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Section from '../components/shared/section';
import ContentBlock from '../components/shared/content-block';
import { setPage, openModal } from '../store/app';

const TermsOfService = ({ setPage }) => {
  useEffect(() => {
    setPage('terms-of-service');
  }, [setPage]);

  return (
    <>
      <Section className="bg-grey-light-new p-10">
        <ContentBlock className="new-content-block text-justify terms-content">
          <h1 style={{ fontSize: 24, textAlign: 'center', marginBottom: 12 }}>
            Regulamin korzystania z aplikacji HORSEmanago
          </h1>

          <hr style={{ margin: '24px 0', height: 1, background: '#292929' }} />

          <h2 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>I. Definicje</h2>
          <p>Na potrzeby niniejszego Regulaminu wskazane poniżej pojęcia będą miały następujące znaczenie:</p>
          <ol>
            <li>
              <p>
                <b>Administrator</b> – Vasco Electronics Góralski Group S.K.A. Al. 29 Listopada 20, 31-401 Kraków,
                wpisana do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla
                Krakowa-Śródmieścia w Krakowie, Wydział XII Gospodarczy Krajowego Rejestru Sądowego (KRS), pod numerem
                KRS: 0000421705, posiadająca numer NIP: 6772369151, numer REGON: 12258185000000, adres e-mail:{` `}
                <a href="mailto:office@vasco-electronics.com" className="link">
                  office@vasco-electronics.com
                </a>
                .
              </p>
            </li>
            <li>
              <p>
                <b>Aplikacja</b> – dystrybuowana przez lub za zgodą Administratora aplikacja HORSEmanago – aplikacja
                webowa, uruchamiana w przeglądarce, przystosowana do pracy na smartfonach oraz tabletach (wersja
                webowa-mobilna) lub aplikacja do korzystania za pomocą komputera z dostępem do Internetu przystosowana
                do urządzeń o dużym ekranie (wersja webowa), umożliwiająca dostęp do Usług.
              </p>
            </li>
            <li>
              <p>
                <b> Urządzenie Mobilne</b> – przenośne urządzenia elektroniczne, podłączone do sieci Internet z
                wykorzystaniem technologii bezprzewodowej (3G, LTE, Wi-Fi) korzystające z systemu operacyjnego Android
                lub iOS.
              </p>
            </li>
            <li>
              <p>
                <b>Usługi</b> – usługi świadczone drogą elektroniczną przez Administratora przy wykorzystaniu Aplikacji,
                w szczególności usługi polegające na umożliwianiu dokonania rezerwacji usług świadczonych przez Stadninę
                w ramach prowadzonej działalności i umożliwienie szczegółowego wyboru zasad świadczenia tych usług przez
                Stadninę (data, koń, miejsce, Instruktor etc).
              </p>
            </li>
            <li>
              <p>
                <b>Użytkownik</b> – każdy podmiot korzystający z Aplikacji w celu uzyskania Usług w tym także Stadnina,
                Pracownik i Klient.
              </p>
            </li>
            <li>
              <p>
                <b>Klient</b> – Użytkownik korzystający z Usług w zakresie rezerwacji i oceny usług świadczonych przez
                Stadninę i/lub Pracownika.
              </p>
            </li>
            <li>
              <p>
                <b>Pracownik</b> – Użytkownik korzystający z Usług w zakresie oferowania swoich usług, jako pracownik
                jazdy konnej w danej Stadninie. O statusie Pracownika decyduje Stadnina.
              </p>
            </li>
            <li>
              <p>
                <b>Stadnina</b> – Użytkownik korzystający z Usług, prowadzący działalność jako stadnina koni,
                udostępniający za pośrednictwem Aplikacji informacje o swoich zasobach (w szczególności konie, miejsca,
                Pracownicy) w celu umożliwienia ich rezerwacji/oceny. Pod pojęciem Stadnina na potrzeby niniejszego
                Regulaminu rozumie się również Kluby Sportowe, Pensjonaty, Ośrodki Jeździeckie.
              </p>
            </li>
          </ol>
          <h2 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>II. Informacje ogólne</h2>
          <ol>
            <li>
              <p>
                Niniejszy Regulamin określa zasady, zakres i warunki korzystania przez Użytkowników z Usług świadczonych
                przez Administratora za pośrednictwem Aplikacji.
              </p>
            </li>
            <li>
              <p>
                Użytkownik jest zobowiązany do korzystania z Aplikacji w sposób zgodny z obowiązującym prawem, normami
                społecznymi i obyczajowymi oraz postanowieniami niniejszego Regulaminu.
              </p>
            </li>
            <li>
              <p>
                W odniesieniu do Użytkowników innych niż Stadnina pobranie Aplikacji z dedykowanych dla danego
                oprogramowania sklepów (wersja mobilna), jak również korzystanie z oferowanych za jej pośrednictwem
                Usług jest bezpłatne. Administrator zastrzega, że może wprowadzić dodatkowe płatne usługi
                funkcjonalności, dostępne na podstawie odrębnych regulaminów. Za dokonywanie płatności przez
                Użytkowników może być pobierana opłata przez dostawcę usług płatności na podstawie regulaminu usług
                obejmujących płatności elektroniczne. Finansowe zasady udostępniania Aplikacji i Usług Stadninie określa
                odrębne porozumienie pomiędzy Administratorem i Stadniną.
              </p>
            </li>
            <li>
              <p>
                Administrator nie jest dostawcą usług transmisji danych. Koszty transmisji danych wymaganych do
                pobrania, instalacji, uruchomienia i/lub korzystania z Aplikacji pokrywają jej Użytkownicy we własnym
                zakresie na podstawie umów zawartych z operatorami telekomunikacyjnymi lub innym dostawcą Internetu.
                Administrator nie ponosi odpowiedzialności za wysokość opłat naliczanych z tytułu wykorzystania
                transmisji danych niezbędnych do korzystania z Aplikacji.
              </p>
            </li>
            <li>
              <p>
                Administrator pośredniczy wyłącznie w przekazaniu oświadczenia woli Użytkowników i w żadnym wypadku nie
                jest:
              </p>
              <ol style={{ listStyle: 'lower-alpha' }}>
                <li>
                  <p>stroną zawartej pomiędzy Stadniną lub Pracownikiem a Klientem umowy,</p>
                </li>
                <li>
                  <p>
                    pełnomocnikiem Stadniny lub Pracownika lub Klienta w zakresie zawarcia jakiejkolwiek umowy między
                    nimi,
                  </p>
                </li>
                <li>
                  <p>pośrednikiem działającym na zlecenie Użytkownika,</p>
                </li>
                <li>
                  <p>oferentem lub organizatorem usług objętych ofertą Stadniny.</p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                Umowa o świadczenie Usług w odniesieniu do kont Użytkowników innych niż Stadnina zostaje zawarta w
                momencie rejestracji konta w Aplikacji.
              </p>
            </li>
            <li>
              <p>
                Umowa o świadczenie Usług wygasa z momentem zakończenia okresu próbnego korzystania z Aplikacji
                określonego przez Administratora lub z momentem usunięcia konta przez Użytkownika, w zależności od tego,
                co nastąpi pierwsze. W przypadku Klienta – brak wyboru jakiejkolwiek Stadniny przez okres dłuższy niż 3
                miesiące uprawnia Administratora do usunięcia konta tego Użytkownika. Konto Stadniny może zablokować lub
                usunąć Administrator na zasadach określonych w umowie pomiędzy Stadniną a Administratorem. Blokada lub
                usunięcie konta Stadniny odnosi analogiczny skutek w odniesieniu do konta Pracownika i w zakresie, w
                jakim dotyczy danej Stadniny. Usunięcie konta Stadniny powoduje utratę dostępu Klientów do danych
                związanych z korzystaniem z usług danej Stadniny. W celach dowodowych dane te mogą być przechowywane do
                czasu przedawnienia roszczeń przez Administratora.
              </p>
            </li>
          </ol>
          <h2 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>III. Prawa autorskie i pokrewne</h2>
          <ol>
            <li>
              <p>
                Administrator oświadcza, że przysługują mu prawa własności intelektualnej (w tym autorskie prawa
                majątkowe) do Aplikacji, know-how, technologii oraz informacji technicznej dotyczącej Aplikacji i
                świadczonych Usług.
              </p>
            </li>
            <li>
              <p>
                Administrator udziela Użytkownikowi prawa do korzystania z Aplikacji na zasadach określonych w
                niniejszym Regulaminie.
              </p>
            </li>
            <li>
              <p>
                Użytkownik uprawniony jest do korzystania z Aplikacji wyłącznie w zakresie niezbędnym do korzystania z
                Usług zgodnie z Regulaminem.
              </p>
            </li>
            <li>
              <p>
                Użytkownik nie ma prawa zwielokrotniać, sprzedawać lub w inny sposób wprowadzać do obrotu lub
                rozpowszechniać Aplikacji, w całości bądź we fragmentach, w szczególności przesyłać lub udostępniać jej
                w systemach i sieciach komputerowych, systemach dystrybucji aplikacji mobilnych lub jakichkolwiek innych
                systemach teleinformatycznych.
              </p>
            </li>
            <li>
              <p>Użytkownik nie ma prawa do:</p>
              <ol style={{ listStyle: 'lower-alpha' }}>
                <li>
                  <p>dokonywania lub umożliwiania dokonania kopii Aplikacji,</p>
                </li>
                <li>
                  <p>
                    udostępniania Aplikacji osobom trzecim do używania pod jakimkolwiek tytułem (odpłatnym lub
                    darmowym),
                  </p>
                </li>
                <li>
                  <p>
                    dokonywania opracowań Aplikacji oraz innych czynności mających na celu stworzenie programów
                    komputerowych o analogicznym lub podobnym jak Aplikacja przeznaczeniu lub funkcji,
                  </p>
                </li>
                <li>
                  <p>
                    dokonywania innych modyfikacji Aplikacji w całości lub w części, w tym w celu rozwijania Aplikacji i
                    tworzenia jego rozszerzeń, aktualizacji i opracowań, w tym także stanowiących utwory zależne,
                    samoistne lub części innego utworu, w tym utworu zbiorowego,
                  </p>
                </li>
                <li>
                  <p>wynajmowania, oddawania w leasing lub współużytkowania albo sublicencjonowania Aplikacji,</p>
                </li>
                <li>
                  <p>dekodowania, rozpowszechniania oraz dekompilacji Aplikacji,</p>
                </li>
                <li>
                  <p>wykonywania praw zależnych do Aplikacji</p>
                </li>
              </ol>
            </li>
          </ol>
          <h2 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>IV. Zasady świadczenia Usług</h2>
          <h3 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>A. Informacje ogólne</h3>
          <ol>
            <li>
              <p>
                Administrator nie gwarantuje nieprzerwanego świadczenia Usług ani parametrów jakości dostępu do nich.
                Parametry jakości dostępu są uzależnione od przepustowości internetowej sieci pośredniczącej pomiędzy
                Administratorem a Użytkownikiem oraz innych czynników niezależnych od Administratora.
              </p>
            </li>
            <li>
              <p>
                Administrator zastrzega sobie prawo ograniczenia, zawieszania lub zaprzestania świadczenia Usług w
                części lub w całości na zasadach określonych w Regulaminie.
              </p>
            </li>
            <li>
              <p>
                Korzystanie przez Użytkownika z Aplikacji lub Usług w sposób sprzeczny z prawem, zasadami współżycia
                społecznego, dobrymi obyczajami lub niniejszym Regulaminem uzasadnia natychmiastowe zaprzestanie
                świadczenia Usługi dla danego Użytkownika oraz blokadę konta lub jego usunięcie.
              </p>
            </li>
            <li>
              <p>
                Treść Regulaminu przekazywana jest Użytkownikowi nieodpłatnie przy pierwszym uruchomieniu Aplikacji.
                Przy pierwszym uruchomieniu Aplikacji Użytkownik ma obowiązek zapoznać się z Regulaminem. Akceptacja
                Regulaminu jest dobrowolna, jednak stanowi warunek korzystania z Aplikacji i świadczonych Usług.
              </p>
            </li>
            <li>
              <p>
                Wszelkie dane wprowadzane przez Użytkowników w czasie korzystania z Aplikacji stanowią własność
                Użytkowników i ponoszą oni za nie pełną odpowiedzialność. Administrator nie ponosi odpowiedzialności za
                poprawność danych wprowadzonych w ramach korzystania z Usług.
              </p>
            </li>
          </ol>

          <h3 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>B. Wymagania techniczne</h3>
          <ol>
            <li>
              <p>
                Użytkownik chcący uzyskać dostęp do Usług musi spełniać wymogi określone w ust. 2 do korzystania z
                Aplikacji w wersji webowej.
              </p>
            </li>
            <li>
              <p>
                W celu prawidłowego uruchomienia Usług w ramach Aplikacji w wersji webowej i webowo-mobilnej wymagane
                jest:
              </p>
              <ol style={{ listStyle: 'lower-alpha' }}>
                <li>
                  <p>połączenie z siecią Internet;</p>
                </li>
                <li>
                  <p>
                    brak aktywnych blokad typu firewall do routera brzegowego dostawcy usług internetowych Użytkownika,
                    uniemożliwiających nawiązywanie połączenia lub ingerujących w nawiązane połączenie w protokołach
                    wykorzystywanych w związku z prezentacją treści
                  </p>
                </li>
                <li>
                  <p>
                    połączenie z Internetem jest wymagane, aby pobrać nowe treści, dokonać rezerwacji i ew. płatności za
                    usługi dostępne w Aplikacji. Dla treści już pobranych nie jest ono wymagane.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <h3 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>C. Rejestracja i korzystanie</h3>
          <ol>
            <li>
              <p>Konto Stadninie zakłada Administrator na podstawie indywidualnego wniosku Stadniny.</p>
            </li>
            <li>
              <p>
                Użytkownik inny niż Stadnina w celu dokonania rejestracji powinien: (a) prawidłowo wypełnić formularz
                rejestracyjny albo w przypadku rejestracji za pośrednictwem aplikacji komputerowej umożliwiającej
                korzystanie z Usług z wykorzystaniem zewnętrznych danych autoryzacyjnych – w szczególności, choć nie
                wyłącznie facebook connect – uruchomić tę aplikację i (b) zaakceptować Regulamin poprzez zaznaczenie
                odpowiedniego przycisku wyboru. Potwierdzenie rejestracji dokonywanej za pośrednictwem formularza
                rejestracyjnego następuje poprzez kliknięcie w link aktywacyjny przesłany na adres e-mail Użytkownika.
                Celem dokonania rejestracji, za pośrednictwem Aplikacji, Użytkownik jest zobowiązany podać w formularzu
                rejestracyjnym w Aplikacji następujące dane:
              </p>
              <ol style={{ listStyle: 'lower-alpha' }}>
                <li>
                  <p>imię i nazwisko,</p>
                </li>
                <li>
                  <p>data urodzenia,</p>
                </li>
                <li>
                  <p>adres e-mail (login),</p>
                </li>
                <li>
                  <p>nr telefonu,</p>
                </li>
                <li>
                  <p>hasło.</p>
                </li>
              </ol>
            </li>
          </ol>
          <p>
            W przypadku rejestracji za pośrednictwem aplikacji komputerowej umożliwiającej korzystanie z Usług z
            wykorzystaniem zewnętrznych danych autoryzacyjnych Użytkownik w celu dokonania rejestracji zobowiązany
            będzie do podania brakujących w stosunku do podanych w ramach rejestracji na serwisie integrowanym danych
            osobowych.
          </p>
          <ol start="3">
            <li>
              <p>
                Użytkownik musi być pełnoletni. Możliwe jest założenie przez Klienta subkonta dla niepełnoletniego
                dziecka Klienta, którym zarządzać może Klient. Za umieszczenie i przetwarzanie danych niepełnoletniego
                dziecka Klienta odpowiedzialność ponosi Klient.
              </p>
            </li>
            <li>
              <p>
                Użytkownik, dokonując rejestracji konta, a także korzystając z Aplikacji, powinien podać dane prawdziwe,
                dokładne, kompletne i niewprowadzające w błąd. Administrator nie weryfikuje prawdziwości podanych
                danych. Użytkownik ponosi wyłączną odpowiedzialność za prawdziwość podanych podczas rejestracji oraz
                korzystania z Aplikacji danych, w tym danych osobowych, zarówno swoich, jak i osób trzecich. W przypadku
                podawania danych osób trzecich, Użytkownik oświadcza, że posiada wszelkie zgody tych osób na podanie
                tych danych, w tym danych osobowych.
              </p>
            </li>
            <li>
              <p>
                Po dokonaniu rejestracji, korzystanie z Usług wymaga każdorazowego połączenia online oraz autentykacji
                przed każdorazowym zalogowaniem się do Aplikacji, w tym – w określonych sytuacjach – z możliwością
                wykorzystania zewnętrznych danych autoryzacyjnych.
              </p>
            </li>
            <li>
              <p>
                Administrator może odmówić utworzenia konta Użytkownika albo zablokować lub usunąć istniejące konto
                Użytkownika, jeżeli login jest już używany w ramach Aplikacji lub jeżeli poweźmie on uzasadnioną,
                wiarygodną informację, że dane na koncie Użytkownika lub sposób korzystania z Usług są sprzeczne z
                prawem, niniejszym Regulaminem, dobrymi obyczajami, naruszają dobra osobiste lub uzasadnione interesy
                Administratora lub osób trzecich. W takim przypadku Administrator może również zablokować świadczenie
                Usług Użytkownikowi
              </p>
            </li>
            <li>
              <p>
                Stadnina lub upoważniony przez Stadninę Pracownik, może również w związku z realizacją Usług wprowadzać
                dane osób zatrudnionych w Stadninie, Klientów oraz klientów nie będących zarejestrowanymi Użytkownikami.
                Za treść i poprawność tych danych oraz ich przetwarzanie i powierzenie przetwarzania Pracownikowi
                zgodnie z obowiązującym prawem odpowiada Stadnina jako administrator danych osobowych. Tak wprowadzone
                dane klienta i związane w nimi informacje zostaną powiązane z kontem Użytkownika w przypadku założenia
                konta przez tego klienta.
              </p>
            </li>
          </ol>
          <h3 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>D. Reklamacje</h3>
          <ol>
            <li>
              <p>
                Reklamacje z tytułu nieprawidłowego dostępu do Usług należy zgłaszać Administratorowi za pośrednictwem
                poczty elektronicznej na adres wskazany w niniejszym Regulaminie.
              </p>
            </li>
            <li>
              <p>Zgłoszenie reklamacji powinno zawierać:</p>
              <ol style={{ listStyle: 'lower-alpha' }}>
                <li>
                  <p>imię, nazwisko;</p>
                </li>
                <li>
                  <p>adres e-mail;</p>
                </li>
                <li>
                  <p>nazwę i model Urządzenia mobilnego, na którym wystąpił problem;</p>
                </li>
                <li>
                  <p>
                    w miarę możliwości (w celu przyspieszenia rozpoznania sprawy) – nazwę i wersję systemu operacyjnego
                    Urządzenia mobilnego, na który wystąpił problem (wersja systemu operacyjnego powinna zostać podana w
                    sposób jak najbardziej szczegółowy, dostępny w menu „Informacje o telefonie" lub podobnym, np.
                    „Android 4.4.3 numer kompilacji KTU84L", „iOS wersja 7.0 numer kompilacji 11A4665");
                  </p>
                </li>
                <li>
                  <p>opis przedmiotu reklamacji, w tym datę i godzinę zaistnienia problemu, itp.;</p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                Prawidłowo zgłoszone reklamacje zostaną rozpatrzone nie później niż w ciągu 14 dni roboczych liczonych
                od momentu otrzymania kompletnej informacji o powstałej nieprawidłowości.
              </p>
            </li>
          </ol>
          <h3 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>E. Odpowiedzialność</h3>
          <ol>
            <li>
              <p>
                Użytkownik korzysta z Aplikacji dobrowolnie, na własną odpowiedzialność. Odpowiedzialność Administratora
                za ewentualne szkody powstałe w związku z korzystaniem z Aplikacji i Usług, a w szczególności brak
                funkcjonowania Aplikacji czy brak dostępu do Usług, a także nieprawidłowe ich funkcjonowanie wyłączona
                jest w możliwie najszerszym, prawnie dopuszczalnym zakresie. W szczególności odpowiedzialność
                Administratora w stosunku do Użytkowników innych niż osoby fizyczne korzystające z Usług w sposób
                niezwiązany bezpośrednio z ich działalnością gospodarczą lub zawodową nie obejmuje utraconych korzyści.
              </p>
            </li>
            <li>
              <p>
                Administrator nie gwarantuje spełnienia oczekiwań Użytkownika w zastosowaniu Aplikacji i Usług, w tym w
                ramach prowadzonej przez niego działalności. Administrator zastrzega, że Aplikacja jest udostępniana
                Użytkownikowi „as it is", czyli taka, jaka jest.
              </p>
            </li>
            <li>
              <p>
                Administrator zapewnia, że Aplikacja tworzona jest z zachowaniem należytej staranności, ale z uwagi na
                fakt, iż jest ona produktem intelektualnym, w którym nie ma możliwości przewidzenia wszelkich jej zasad
                zachowania się i działania, Administrator nie gwarantuje, że jest ona pozbawiona zupełnie błędów/wad.
              </p>
            </li>
            <li>
              <p>
                Administrator nie ponosi odpowiedzialności za ograniczenia lub problemy techniczne w systemach
                teleinformatycznych, z których korzystają Użytkownicy, a które uniemożliwiają lub ograniczają
                Użytkownikom korzystanie z Aplikacji i oferowanych za jej pośrednictwem Usług. Administrator nie ponosi
                odpowiedzialności za niezadowalającą Użytkownika jakość, wydajność, dokładność Aplikacji.
              </p>
            </li>
            <li>
              <p>
                W działaniu Aplikacji mogą wystąpić przerwy spowodowane przyczynami technicznymi, w szczególności
                wynikłe z konieczności konserwacji Aplikacji lub systemów obsługujących. O planowanej przerwie w
                działaniu Aplikacji, Użytkownik będzie informowany poprzez zamieszczenie w Aplikacji odpowiedniej
                informacji.
              </p>
            </li>
            <li>
              <p>
                Świadczenie Usługi może zostać przerwane w razie nienależytej jakości połączenia, uszkodzeń lub wad
                urządzeń telekomunikacyjnych, systemów zasilania, sprzętu komputerowego, awarii sieci
                telekomunikacyjnej, przerw w dostawie prądu lub jakiegokolwiek działania osób trzecich w trakcie
                transakcji.
              </p>
            </li>
            <li>
              <p>
                Administrator ma prawo zablokować dostęp do Aplikacji lub poszczególnych Usług w razie stwierdzenia
                nieprawidłowości w korzystaniu z Aplikacji.
              </p>
            </li>
          </ol>
          <h2 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>V. Płatności Online</h2>
          <p>
            Operatorem płatności online jest Blue Media S.A. z siedzibą w Sopocie, 81-718 Sopot, ul. Powstańców Warszawy
            6, NIP 585-13-51-185. W przypadku wystąpienia konieczności zwrotu środków za transakcję dokonaną przez
            klienta kartą płatniczą sprzedający dokona zwrotu na rachunek bankowy przypisany do karty płatniczej
            Zamawiającego.
          </p>
          <p>
            W przypadku płatności kartą termin realizacji liczy się od momentu pozytywnej autoryzacji transakcji.
            Dostępne formy płatności: Karty płatnicze: Visa, MasterCard, przelewy online, BLIK.
          </p>
          <p>
            Sprzedawca zastrzega, że wskazane formy płatności mogą być modyfikowane, okresowo wyłączane lub wymieniane
            na inne.
          </p>
          <p>
            Reklamacje wynikające z tytułu niewykonania lub niewłaściwego wykonania obsługi płatności należy kierować do
            właściwego pośrednika płatności bezpośrednio na adres e-mail, korzystając z formularza kontaktowego lub
            telefonicznie-zgodnie z regulaminem świadczenia usług w zakresie płatności elektronicznych danego agenta
            rozliczeniowego.
          </p>
          <p>
            W wypadku niewywiązania się przez Klienta z płatności w terminie, który zostanie wskazany w toku składania
            Zamówienia, Sprzedawca może wyznaczyć dodatkowy termin na dokonanie płatności. Sprzedawca może też odstąpić
            od Umowy sprzedaży z uwagi na jej nieopłacenie po upływie terminu płatności. Oświadczenie o odstąpieniu od
            Umowy sprzedaży na tej podstawie zostanie przesłane do Klienta w formie dokumentowej.
          </p>
          <p>
            Klient zezwala na przesyłanie drogą elektroniczną paragonu, faktury lub innych przewidzianych prawem dowodów
            zakupu.
          </p>
          <h2 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>VI. Postanowienia końcowe</h2>
          <p>
            Niniejszy regulamin może ulec zmianie. O zmianie regulaminu Użytkownicy zostaną powiadomieni za pomocą
            wiadomości e-mail. W przypadku braku akceptacji zmian, Użytkownik powinien wypowiedzieć umowę w sposób
            określony w Regulaminie pkt. II.7. W sprawach nieuregulowanych Regulaminem zastosowanie mają odpowiednie
            przepisy prawa polskiego, a w szczególności Kodeksu cywilnego (t.j. Dz. U. z 2017 roku, poz. 459 ze zm.),
            Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony
            osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych
            oraz uchylenia dyrektywy 95/46/WE, ustawy z dnia 10 maja 2018 r. o ochronie danych osobowych (Dz.U.Rok:
            2018. poz.: 1000), ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (t.j. Dz. U. z
            2017 roku, poz. 1219 ze zm.), ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych (t.j.
            Dz.U. z 2017 r., poz. 880 ze zm.).
          </p>
        </ContentBlock>
      </Section>
    </>
  );
};

export default connect(
  state => ({
    pageName: state.app.pageName,
  }),
  dispatch => ({
    setPage: pageName => dispatch(setPage(pageName)),
    openModal: modalName => dispatch(openModal(modalName)),
  })
)(TermsOfService);
